<template>
  <div>
    <div id="product" v-if="current_active_module">
      <!-- loader starts here  -->
      <loading :active.sync="isLoading" :can-cancel="false" loader="dots" color="#007bff" :height="45" :width="75"
        :is-full-page="fullPage"></loading>


      <!-- ends here -->
      <v-data-table :headers="headers" v-model="selected" :single-select="singleSelect" show-select item-key="product_id"
        hide-default-footer :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :items="desserts" :options.sync="options"
        class="elevation-1" @page-count="pageCount = $event">
        <template v-slot:top>
          <div id="product__filter">
            <v-col cols="5">
              <v-select :items="sectors" dense multiple item-text="name" item-value="key" v-model="sector_data"
                label="Sector" />
            </v-col>
            <v-col cols="4" v-if="displayButtons">
              <v-btn class="mx-2" small color="primary" @click="search">
                <strong>Search</strong>
              </v-btn>
              <v-btn class="mx-2" small color="default" @click="reset">
                <strong>Reset</strong>
              </v-btn>
            </v-col>
          </div>
          <searchForm @data="data" @search="search" @reset="reset" @addCompany="addCompany" @removeCompany="removeCompany"
            :formType="'WEBSITEURL_PUBLISHED'" @showButtons="showButtons" :stateName="'WebsiteUrlPublished'" />
          <v-container class="grey lighten-5" id="product__container">
            <v-row no-gutters>
              <v-col cols="12">
                <CopyToClipboard :muids="selectedEndPointUrls" />
                <div style="float:right"><v-btn color="primary" small v-show="checkIfOperationExistForModule('delete')"
                    @click.stop="deleteproduct()">Delete</v-btn></div>
                <!-- <div style="float:right"><v-btn color="primary" small @click.stop = "addnewproduct(1)" >Add Product</v-btn></div> -->
                <!-- <div style="float:right"><v-btn color="primary" small @click.stop = "massproductupdate()" >Bulk Update</v-btn></div> -->
              </v-col>

            </v-row>
          </v-container>
        </template>
        <!-- product preview column -->
        <template v-slot:item.product_id="{ item }" style="display:inline-flex;">
          {{ item.product_id }}
          <div style="display: block">
            <v-icon @click="addItemV2(item)" color="blue darken-2" small class="mr-2"
              title="Update Product V2">mdi-circle-edit-outline</v-icon>
            <v-icon dark right size=20 color="" @mouseover="manage_preview(item.product_id)">mdi-file-pdf-box
            </v-icon>
          </div>
          <div class="pdfcontent" v-show="preview_loaded && hover_product_id === item.product_id">
            <div style="positon: relative; background-color: #3f51b5; color: #fff">
              <v-icon @click="preview_loaded = false" style="color: #fff">
                mdi-close
              </v-icon>
              <span @click="openpdfwindow(item.product_id)" style="margin-right: 5px;
                                                                        margin-left: 5px;text-decoration: none;color: #fff;float: right;cursor: pointer;
                                                                        ">View PDF Content</span>
            </div>
            <div>
              <v-img class="border border-dark" :lazy-src="imgarr[item.product_id]" :max-height="500" :max-width="400"
                :src="imgarr[item.product_id]" @click="manage_pdf(item.product_id)" style="cursor: pointer">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </div>
          </div>
        </template>
        <template v-slot:item.company="{ item }">
          <span>{{ companyName(item.product_id) }}</span>
        </template>
        <template v-slot:item.modify_date="{ item }">
          <span>{{ formateDate(item.modify_date) }}</span>
        </template>
        <template v-slot:item.userpopup="{ item }">
          <span @click.stop="openlogmodal(item.product_id, item.muid)" style="color:blue;cursor:pointer">{{
            userName(item.product_id)
          }}</span>
        </template>
        <template v-slot:item.mchannel_sector_audience="{ item }">
          <span v-html="mchannelSectorAudience(item)"></span>
        </template>
        <template v-slot:item.product_status="{ item }">
          <span>{{ displayStatus(item.product_status) }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          {{ item.product_id }} / {{ item.muid }}
          <div v-if="item.product_status == 11">
            <v-icon small class="mr-2" @click="addItem(item)">mdi-plus</v-icon>
            <v-icon small @click="editItem(item)">mdi-pencil</v-icon>
          </div>
          <div v-else>
            <v-icon small class="mr-2" @click="addItem(item)">mdi-format-list-bulleted</v-icon>
          </div>
        </template>
      </v-data-table>
      <div class="text-center pt-4 pb-4">
        <Pagination :isloading="isLoading" :startRecord="startRecord" :currentPage="currentPage" :lastPage="lastPage"
          :lastRecord="lastRecord" :totRecords="totRecords" :isCurrentPageClass="isCurrentPageClass" :perpage="perpage"
          :getLastPageClass="getLastPageClass" :totPage="totPage" :getPages="getPages" @handlePerPage="handlePerPage"
          @paginate="paginate" @last="last" @getDataByPage="getDataByPage" :showPerPage="showPerPage" />
      </div>
      <!-- dialog box-->
      <v-dialog v-model="massupdatedialog" max-width="650">
        <MassUpdate :selected="selected" @massupdatedialogStatus="massupdatedialogStatus" />
      </v-dialog>

      <!-- dialog box-->
      <!-- dialog box-->
      <v-dialog v-model="productadmindialog" max-width="400">
        <v-card>
          <v-card-title class="headline" style="padding:10px;font-size:16px !important"> Product Activity Log
          </v-card-title>
          <br />
          <v-data-table :headers="userheaders" hide-default-footer disable-pagination :items="logdata"
            class="elevation-1">
            <template v-slot:item.logDate="{ item }">
              <span>{{ formateDate(item.logDate) }}</span>
            </template>

          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="green darken-1" text @click="productadmindialog = false">
              Close
            </v-btn>

          </v-card-actions>
        </v-card>

      </v-dialog>
    </div>
    <div id="product_div" v-else>
      <div id="product_loader"></div>
    </div>
  </div>
</template>
<script>
import Pagination from "./component/Pagination.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import searchForm from "./component/searchForm.vue";
import MassUpdate from "./component/MassUpdate";
import Tabs from "./component/Tabs.vue";
import { mapGetters } from "vuex";
import CopyToClipboard from "./component/CopyToClipboard.vue";

export default {
  name: "TempProduct",
  components: {
    Loading,
    Pagination,
    searchForm,
    MassUpdate,
    Tabs,
    CopyToClipboard,
  },
  data() {
    return {
      imgarr: {},
      pdf_link: {},
      massupdatedialog: false,
      preview_loaded: false,
      hover_product_id: "",
      menu: "",
      filtermuid: "",
      fullPage: true,
      productadmindialog: false,
      logdata: [],
      isLoading: false,
      fullPage: true,
      rowsPerPageItems: [25, 50, 100],
      sortDesc: true,
      sortBy: "modify_date",
      singleSelect: false,
      selected: [],
      search_product_id: "",
      selectedcompanyid: "",
      search_product_name: "",
      selectedstateid: "",
      sort: "",
      loading: true,
      options: {},
      showPerPage: true,
      userheaders: [
        {
          text: "UserName",
          value: "userid",
          width: "40%",
          class: "",
        },
        {
          text: "Action",
          value: "ProductActivity",
          width: "40%",
          class: "",
        },
        {
          text: "Date",
          value: "logDate",
          width: "20%",
          class: "",
          sortable: false,
        },
      ],
      headers: [
        {
          text: "Product Id",
          align: "start",
          sortable: true,
          value: "product_id",
          class: "v-data-table-header",
          width: "13%",
        },
        {
          text: "Date",
          value: "modify_date",
          width: "10%",
          class: "v-data-table-header",
        },
        {
          text: "Entry ID",
          value: "entry_id",
          width: "16%",
          class: "v-data-table-header",
        },
        {
          text: "Product",
          value: "product_name",
          width: "17%",
          sortable: true,
          class: "v-data-table-header",
        },

        {
          text: "Company",
          value: "company",
          width: "13%",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Sector/Audience",
          value: "mchannel_sector_audience",
          sortable: false,
          width: "18%",
          class: "v-data-table-header",
        },
        {
          text: "Last User",
          value: "userpopup",
          sortable: false,
          width: "10%",
          class: "v-data-table-header",
        },
      ],
      logmuid: "",
      data: "temp_product",
      sectors: [],
      product_status: [],
      displayButtons: true,
    };
  },
  mounted() {
    var view = this,
      raw_data_endpoint = view.$url("PRODUCT_RAW_DATA");
    view.$productrouteconst.fetchAllProductStatus(view);
    view.$store
      .dispatch("WebsiteUrlPublished/setIsSearch", true)
      .then(function () {
        return view.$store.dispatch("WebsiteUrlPublished/setProductStatus", [
          view.$productrouteconst.APPROVED,
        ]);
      })
      .then(function () {
        return view.$store.dispatch("WebsiteUrlPublished/setMediaChannels", [
          view.$productrouteconst.WEBSITE_URL,
        ]);
      });
    view
      .$fetch({ requiresAuth: true })
      .get(raw_data_endpoint)
      .then((response) => {
        view.sectors = response.data.payload;
      })
      .catch((e) => console.log(e));
    view.product_status = view.$productrouteconst.product_status();
  },
  computed: {
    ...mapGetters({
      current_active_module: "Rawdata/getCurrentActiveModule",
    }),
    sector_data: {
      get: function () {
        return this.$store.getters["WebsiteUrlPublished/getSectors"];
      },
      set: function (arr) {
        var view = this,
          all = view.sectors.filter((e) => e.key !== "0"),
          selected = arr.filter((e) => e === "0"),
          saved = view.$store.getters["WebsiteUrlPublished/getSectors"],
          flag = true;
        if (selected.length > 0) {
          if (
            arr.filter((e) => e !== "0").length <
            saved.filter((e) => e !== "0").length
          ) {
            arr = arr.filter((e) => e !== "0");
            flag = false;
          } else {
            all.forEach(function (e) {
              arr.push(e.key);
            });
          }
        }
        if (saved.includes("0") && !arr.includes("0")) {
          if (flag) {
            arr = new Array();
          }
        }
        arr = [...new Set(arr)];
        view.$store.dispatch("WebsiteUrlPublished/setSectors", arr);
      },
    },
    product_status_data: {
      get: function () {
        return this.$store.getters["WebsiteUrlPublished/getProductStatus"];
      },
      set: function (o) {
        this.$store.dispatch("WebsiteUrlPublished/setProductStatus", o);
      },
    },
    desserts: {
      get: function () {
        return this.$store.getters["WebsiteUrlPublished/getDesserts"];
      },
      set: function () { },
    },
    totalDesserts: {
      get: function () {
        return this.$store.getters["WebsiteUrlPublished/getTotalDesserts"];
      },
      set: function () { },
    },
    totRecords: {
      get: function () {
        return this.$store.getters["WebsiteUrlPublished/getTotRecords"];
      },
      set: function () { },
    },
    pageCount: {
      get: function () {
        return this.$store.getters["WebsiteUrlPublished/getPageCount"];
      },
      set: function () { },
    },
    startRecord: function () {
      return parseInt(this.lastRecord) - parseInt(this.perpage) + parseInt(1);
    },
    lastRecord: function () {
      return this.perpage * this.currentPage;
    },
    isCurrentPageClass: function () {
      return this.currentPage === 1 ? "" : "notactivespan";
    },
    getLastPageClass: function () {
      return parseInt(this.lastPage) === parseInt(this.currentPage)
        ? ""
        : "notactivespan";
    },
    totPage: function () {
      return this.lastPage;
    },
    getPages: function () {
      return this.$utils.getPages(
        this.lastPage,
        this.currentPage,
        this.totPage
      );
    },
    currentPage: function () {
      return this.$store.getters["WebsiteUrlPublished/getPage"];
    },
    lastPage: function () {
      return this.$store.getters["WebsiteUrlPublished/getLastPage"];
    },
    perpage: function () {
      return this.$store.getters["WebsiteUrlPublished/getPerPage"];
    },
    page: function () {
      return this.$store.getters["WebsiteUrlPublished/getPage"];
    },
    selectedEndPointUrls: function () {
      let endpoints = [];
      let view = this;
      if (view.selected.length > 0) {
        view.desserts.forEach(function (item) {
          view.selected.forEach((o) => {
            if (item.product_id == o.product_id) {
              let enpointURL = "";
              if (o.muid === null || o.muid == 0 || o.muid == o.product_id)
                enpointURL =
                  view.$url("ADD_PRODUCT_FORM_URLV2") +
                  o.product_id +
                  "?ec_content_type=website_url";
              else
                enpointURL =
                  view.$url("ADD_PRODUCT_FORM_URLV2") +
                  o.product_id +
                  "?ec_content_type=website_url";
              endpoints.push({ product_id: o.product_id, url: enpointURL });
            }
          });
        });
      }
      return endpoints;
    },
  },
  methods: {
    manage_preview(product_id) {
      this.preview_loaded = true;
      this.hover_product_id = product_id;
    },
    openpdfwindow(product_id) {
      window.open(this.pdf_link[product_id], "_blank", "width=800,height=800");
    },
    checkIfOperationExistForModule(type) {
      return this.$utils.checkIfOperationExistForModule(this, type);
    },

    deleteproduct() {
      var view = this;
      if (view.selected.length > 0) {
        view.$swal
          .fire({
            title: "Are you sure ?",
            text: "This will remove product temporarily.",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes, remove it !",
            cancelButtonText: "No, cancel !",
            closeOnConfirm: false,
            closeOnCancel: false,
          })
          .then((result) => {
            if (result.isConfirmed) {
              var posted_data = {};
              var data = [];
              for (var l in this.selected) {
                let local = {};
                local["product_id"] = this.selected[l].product_id;
                local["index_name"] = this.selected[l].index_name;
                data.push(local);
              }
              posted_data["data"] = data;
              let delete_data = JSON.stringify(posted_data);
              let delelete_endpoint = view.$url("SOFT_DELETE");
              this.$fetch({ requiresAuth: true })
                .post(delelete_endpoint, posted_data)
                .then((response) => {
                  this.$swal.fire({
                    icon: "success",
                    title: "Product Deleted",
                    text: "Product has been deleted successfully",
                  });
                  view.getData();
                })
                .catch(function (response) {
                  if (response.response.status == 401) {
                    window.location.href;
                  }
                });

              view.selected = [];
              console.log(JSON.stringify(posted_data));
              // delete endpoint
            } else {
              view.selected = [];
            }
          });

        //console.log(this.selected)
      } else {
        view.$swal.fire({
          position: "bottom-end",
          icon: "error",
          title: "Please select ProductID",
        });
      }
    },
    formateDate: function (d) {
      return this.$utils.formateDate(d);
    },
    manage_pdf(product_id) {
      let url = this.$url("CLIENT_FRONTEND") + "managepdf/" + product_id;
      url = url.replace(/%20/g, " ");
      window.open(
        url,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,top=100,,width=800,height=800"
      );
    },
    displayStatus: function (i) {
      var view = this,
        all_status = view.$store.getters["Rawdata/getProductStatus"],
        filtered = all_status.filter((o) => o.key_id === i);
      if (Array.isArray(filtered) && filtered.length > 0) {
        return filtered[0].description;
      }
      return "";
    },
    showButtons: function (o) {
      this.displayButtons = !this.displayButtons;
    },
    massupdatedialogStatus(o) {
      var view = this;
      view.massupdatedialog = false;
      view.selected = [];
      if (o == "save") view.getData();
    },
    massproductupdate() {
      var view = this;
      if (view.selected.length > 0) {
        view.massupdatedialog = true;
      } else {
        view.$swal.fire({
          position: "bottom-end",
          icon: "error",
          title: "Please select ProductID",
        });
      }
    },
    displaypreview(product_id) {
      var view = this;
      view.imagelist = [];
      let token = view.$utils.getAccessToken();
      //this.loading = true
      let temproduct_url = view.$url("PRODUCT_PREVIEW");
      // this.pdf_link =
      //   this.$url("DIRECT_MAIL_PDF") + "?product_id=" + product_id;
      view.$fetch({ requiresAuth: true })
        .get(
          temproduct_url + "?product_type=ProductImg&product_id=" + product_id,
          {
            //headers
            headers: {
              Authorization: `${token}`,
            },
          }
        )
        .then((response) => {
          let items = response.data.payload;
          view.imagelist = items;
          for (let i in items) {
            view.imgarr[items[i].productID] = items[i]["downlaod_url"];
            view.pdf_link[items[i].productID] =
              view.$url("DIRECT_MAIL_PDF") +
              "?product_id=" +
              items[i].productID;
          }
          view.preview_loaded = true;
          // this.imagelist = items;
          view.loading = false;
          view.isLoading = false;
        })
        .catch(function (response) {
          view.loading = false;
          view.isLoading = false;
          console.log(response);
        });
    },
    filterdata() {
      this.getData();
    },
    reset() {
      var view = this;
      view.$store.dispatch("WebsiteUrlPublished/setPage", 1);
      view.$store.dispatch("WebsiteUrlPublished/setSorting", {
        field: "modify_date",
        direction: true,
      });
      view.$store
        .dispatch("WebsiteUrlPublished/resetSearch")
        .then(function () {
          return view.$store.dispatch("WebsiteUrlPublished/setPerPage", 10);
        })
        .then(function () {
          return view.$store.dispatch("WebsiteUrlPublished/setIsSearch", true);
        })
        .then(function () {
          return view.$store.dispatch("WebsiteUrlPublished/setProductStatus", [
            view.$productrouteconst.APPROVED,
          ]);
        })
        .then(function () {
          return view.$store.dispatch("WebsiteUrlPublished/setMediaChannels", [
            view.$productrouteconst.WEBSITE_URL,
          ]);
        })
        .then(function () {
          view.sortBy = "modify_date";
          view.sortDesc = true;
          view.getData();
        });
    },
    getDataByPage(n) {
      let view = this,
        paging = view.$store.getters["WebsiteUrlPublished/getPaging"],
        obj = paging.filter((e) => e.page_number === n);
      view.$store
        .dispatch("WebsiteUrlPublished/setPage", n)
        .then(function () {
          return view.$store.dispatch("WebsiteUrlPublished/setPagingObj", obj);
        })
        .then(function () {
          view.getData();
        });
    },
    handlePerPage(n) {
      let view = this;
      view.$store
        .dispatch("WebsiteUrlPublished/setPage", 1)
        .then(function () {
          return view.$store.dispatch("WebsiteUrlPublished/setPerPage", n);
        })
        .then(function () {
          view.options.itemsPerPage = n;
          view.getData();
        });
    },
    paginate(n) {
      if (
        parseInt(n) === parseInt(0) &&
        parseInt(this.currentPage) === parseInt(1)
      ) {
        return;
      }
      if (parseInt(n) === parseInt(-1)) {
        if (parseInt(this.currentPage) <= parseInt(1)) {
          return;
        }
      }
      if (parseInt(n) === parseInt(1)) {
        if (parseInt(this.currentPage) >= parseInt(this.lastPage)) {
          return;
        }
      }
      let view = this,
        paging = view.$store.getters["WebsiteUrlPublished/getPaging"],
        obj = [];
      n =
        parseInt(n) === parseInt(0)
          ? parseInt(1)
          : parseInt(n) + parseInt(view.currentPage);
      obj = paging.filter((e) => e.page_number === n);
      view.$store
        .dispatch("WebsiteUrlPublished/setPagingObj", obj)
        .then(function () {
          return view.$store.dispatch("WebsiteUrlPublished/setPage", n);
        })
        .then(function () {
          view.getData();
        });
    },
    last() {
      let view = this;
      view.$store
        .dispatch("WebsiteUrlPublished/setPage", view.lastPage)
        .then(function () {
          view.getData();
        });
    },
    openlogmodal(product_id, muid) {
      var view = this;
      view.productadmindialog = true;
      view.isLoading = true;
      view.loading = true;
      view.logdata = [];
      let logurl =
        view.$url("PRODUCT_ADMIN_LOG") + product_id + "?type=process";
      let token = view.$utils.getAccessToken();
      this.$fetch({ requiresAuth: true })
        .get(logurl, {
          //headers
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((response) => {
          view.isLoading = false;
          view.loading = false;
          view.logdata = response.data.payload;
          view.logmuid = muid;
        })
        .catch(function (response) {
          if (response.response.status == 401) {
            window.location.href;
            view.loading = false;
          }
        });
    },
    getData: function () {
      let view = this,
        data = {};
      view.loading = true;
      view.isLoading = true;
      view.$store.dispatch(
        "WebsiteUrlPublished/setAllowedSectors",
        view.$store.getters["Rawdata/getSectors"]
      );
      data["request"] =
        view.$store.getters["WebsiteUrlPublished/getRequestPayload"];
      if (view.$store.getters["WebsiteUrlPublished/getIsSearch"]) {
        data["search"] = view.$store.getters["WebsiteUrlPublished/getSearch"];
      }
      view
        .$fetch({
          requiresAuth: true,
          operation: "add",
          vueScope: view,
        })
        .post(view.$url("TEMPPRODUCT"), data)
        .then((response) => {
          let data = response.data.payload,
            lastPage = 0;
          view.desserts = data.payload;
          lastPage = Math.ceil(
            data.totalProducts /
            view.$store.getters["WebsiteUrlPublished/getPerPage"]
          );
          console.log("lastPage", lastPage);
          view.$store.dispatch("WebsiteUrlPublished/setDesserts", data.payload);
          view.$store.dispatch(
            "WebsiteUrlPublished/setTotalDesserts",
            data.totalProducts
          );
          view.$store.dispatch(
            "WebsiteUrlPublished/setTotRecords",
            data.totalProducts
          );
          view.$store.dispatch("WebsiteUrlPublished/setPageCount", lastPage);
          view.$store.dispatch(
            "WebsiteUrlPublished/setExtraData",
            data.extra_data
          );
          view.$store.dispatch(
            "WebsiteUrlPublished/setTotProducts",
            data.totalProducts
          );
          view.$store.dispatch("WebsiteUrlPublished/setLastPage", lastPage);
          if ("paging" in data) {
            if (Array.isArray(data.paging)) {
              view.$store.dispatch(
                "WebsiteUrlPublished/setPaging",
                data.paging
              );
            }
          }
          let product_ids = [];
          view.desserts.forEach((record) => {
            product_ids.push(record.product_id);
          });
          let product_str = product_ids.join(",");
          view.displaypreview(product_str);
          view.loading = false;
          view.isLoading = false;
          view.selected = [];
        })
        .catch(function (e) {
          view.loading = false;
          view.isLoading = false;
          console.log(e);
        });
    },
    addItem(item) {
      var view = this;
      let enpointURL = "";
      if (item.muid == "")
        enpointURL =
          view.$url("ADD_PRODUCT_FORM_URL") +
          item.product_id +
          "?ec_content_type=website_url";
      else
        enpointURL =
          view.$url("ADD_PRODUCT_FORM_URL") +
          item.product_id +
          "?ec_content_type=website_url";

      window.open(enpointURL, "_blank");
    },
    editItem(item) {
      var view = this;
      let muid = "";
      muid = item.muid;
      if (muid != "") {
        let enpointURL = view.$url("EDIT_PRODUCT_FORM_URL") + muid;
        window.open(enpointURL, "_blank");
      }
    },
    companyName(id) {
      let extradata = this.$store.getters["WebsiteUrlPublished/getExtraData"];
      return id in extradata
        ? "company_name" in extradata[id]
          ? this.$utils.checkVal(extradata[id]["company_name"])
          : "--"
        : "--";
    },
    mchannelSectorAudience(i) {
      let view = this,
        str = "",
        extradata = view.$store.getters["WebsiteUrlPublished/getExtraData"],
        sec_name = "",
        pid = i.product_id,
        audience = "",
        str1 = "";
      // str= str + view.$utils.checkVal(i.mChannelName) + '/'

      // sec_name = pid in extradata ? ('sector_name' in extradata[pid] ? extradata[pid]['sector_name'] : '--') : '--'
      // str = str + view.$utils.checkVal(sec_name) + '/'

      for (var i = 0; i <= extradata[pid]["sector_name"].length - 1; i++) {
        str1 = str1 + extradata[pid]["sector_name"][i] + ",";
      }
      str = str1.substring(0, str1.length - 1) + "/";

      audience =
        pid in extradata
          ? "audience" in extradata[pid]
            ? extradata[pid]["audience"]
            : "--"
          : "--";
      str = str + view.$utils.checkVal(audience);
      // for (var i = 1; i <= extradata[pid]['sector_name'].length-1; i++) {
      //     str1 = str1 + extradata[pid]['sector_name'][i] + ","
      // }
      // str = str + "<br/><p style='font-size: 10px;margin-bottom: 0px;font-style: italic'>" + str1 + "</p>"
      return str;
    },
    userName(i) {
      let extradata = this.$store.getters["WebsiteUrlPublished/getExtraData"],
        val =
          i in extradata
            ? "admin_user" in extradata[i]
              ? extradata[i]["admin_user"]
              : "--"
            : "--";
      return val; //this.$utils.checkVal(val)
    },
    search: function () {
      var view = this;
      view.$store
        .dispatch("WebsiteUrlPublished/setPage", 1)
        // .then(function () {
        // return view.$store.dispatch('WebsiteUrlPublished/setPerPage', 10)
        // })
        .then(function () {
          return view.$store.dispatch("WebsiteUrlPublished/setIsSearch", true);
        })
        .then(function () {
          view.getData();
        });
    },
    addCompany: function (c) {
      this.$store.dispatch("WebsiteUrlPublished/addCompany", c);
    },
    removeCompany: function (c) {
      this.$store.dispatch("WebsiteUrlPublished/removeCompany", c);
    },
    addItemV2(item) {
      var view = this;
      let enpointURL = "";
      if (item.muid == "")
        enpointURL =
          view.$url("ADD_PRODUCT_FORM_URLV2") +
          item.product_id +
          "?ec_content_type=website_url";
      else
        enpointURL =
          view.$url("ADD_PRODUCT_FORM_URLV2") +
          item.product_id +
          "?ec_content_type=website_url";

      window.open(enpointURL, "_blank");
    },
  },
  watch: {
    options: {
      handler(o) {
        var view = this,
          sortBy = o.sortBy,
          sortDesc = o.sortDesc;
        if (Array.isArray(sortBy) && Array.isArray(sortDesc)) {
          if (sortBy.length > 0 && sortDesc.length > 0) {
            view.$store
              .dispatch("WebsiteUrlPublished/setPage", 1)
              .then(function () {
                return view.$store.dispatch("WebsiteUrlPublished/setSorting", {
                  field: sortBy[0],
                  direction: sortDesc[0],
                });
              })
              .then(function () {
                view.getData();
              });
          }
        }
      },
    },
  },
};
</script>

<style lang="sass" scoped>
@import './../../sass/product'
</style>
<style >
.previewcontainer {
  position: relative;
}

.pdfcontent {
  left: 170px;
  float: left;
  display: inline;
  z-index: 1000;
  position: absolute;
  transition: all 0.2s;
  margin-top: -18px;
}

.complist ul {
  padding-left: 0;
  list-style: none;
}

.complist li {
  list-style: none;
  float: none;
}

.complist span {
  position: relative;
  margin: 0 5px 0 5px;
  font-size: 14px;
  cursor: pointer;
  color: #00a4e4;
}

.sectorlist ul {
  padding-left: 0;
  list-style: none;
}

.sectorlist ul li {
  float: none;
}

.sectorlist ul li {
  margin: 0 5px 0 5px;
  font-size: 14px;
  cursor: pointer;
  color: #00a4e4;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
  font-size: 11px;
  font-weight: bold;
  color: #fff !important;
}

.mx-3 {
  overflow-x: hidden;
}

.v-menu__content {
  box-shadow: none;
}

.v-application .text-center {
  text-align: left !important;
}
</style>
