<template>
  <div id="searchForm">
    <v-container id="searchForm__container">
      <v-expand-transition>
        <div v-show="expand" no-gutters>
          <v-row>
            <v-col cols="3" v-if="formType != 'PRODUCT_ARCHIEVE'">
              <!-- <v-text-field
                v-on:keyup.enter="searchData"
                v-model="product_id"
                label="Product ID"
              /> -->
              <v-combobox
                v-on:keyup.enter="searchData"
                v-model="product_id"
                class="noicon-combo"
                :items="product_id_autofills"
                label="Product ID"
                ref="product_id"
              />
            </v-col>

            <v-col
              cols="3"
              v-if="
                formType === 'DIRECT_UNPUBLISHED' ||
                formType === 'DIRECT_PUBLISHED'
              "
            >
              <!-- <v-text-field
                v-on:keyup.enter="searchData"
                v-model="entry_id"
                label="Entry ID"
              /> -->
              <v-combobox
                v-model="entry_id"
                class="noicon-combo"
                v-on:keyup.enter="searchData"
                :items="entry_id_autofills"
                label="Entry ID"
                ref="entry_id"
              />
            </v-col>
            <v-col cols="3" v-else>
              <!-- <v-text-field
                v-on:keyup.enter="searchData"
                v-model="entry_id"
                label="Entry ID"
              /> -->
              <v-combobox
                class="noicon-combo"
                v-model="entry_id"
                v-on:keyup.enter="searchData"
                :items="entry_id_autofills"
                label="Entry ID"
                ref="entry_id"
              />
            </v-col>
            <v-col cols="3">
              <!-- <v-text-field
                v-on:keyup.enter="searchData"
                v-model="panelist"
                label="Panelist"
              /> -->
              <v-combobox
                class="noicon-combo"
                v-model="panelist"
                v-on:keyup.enter="searchData"
                :items="panelist_autofills"
                label="Panelist"
                ref="panelist"
              />
            </v-col>
            <v-col cols="3">
              <!-- <v-text-field
                v-on:keyup.enter="searchData"
                v-model="productName"
                label="Product Name"
                required
              /> -->
              <v-combobox
                class="noicon-combo"
                v-model="productName"
                v-on:keyup.enter="searchData"
                :items="productName_autofills"
                label="Product Name"
                ref="productname"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" v-click-outside="clickedOut">
              <div id="searchForm__autocomplete">
                <div
                  v-for="c in choosenCompanies"
                  v-bind:key="c.id"
                  id="searchForm__autocomplete--chip"
                >
                  {{ c.name }}
                  <v-icon
                    dark
                    left
                    id="searchForm__autocomplete--icon"
                    @click="removeCompany(c)"
                  >
                    mdi-minus-circle
                  </v-icon>
                </div>
                <div id="searchForm__autocomplete--chip">
                  <input
                    type="text"
                    id="searchForm__autocomplete--text_field"
                    autofocus
                    v-on:keyup.enter="searchData"
                    placeholder="Choose Company"
                    v-model="company"
                  />
                </div>
              </div>
              <div>
                <ul id="searchForm__list">
                  <li
                    v-for="c in companies"
                    v-bind:key="c.id"
                    @click="chooseCompany(c)"
                  >
                    {{ c.name }}
                  </li>
                </ul>
              </div>
            </v-col>
            <v-col cols="3" style="margin-top: 18px">
              <v-autocomplete
                v-model="username"
                :items="userdata"
                item-text="userName"
                item-value="userID"
                dense
                chips
                small-chips
                label="Users"
                multiple
              />
            </v-col>

            <v-col cols="3" style="margin-top: 18px">
              <v-autocomplete
                v-model="statename"
                :items="statedata"
                item-text="stateName"
                item-value="stateID"
                dense
                chips
                small-chips
                label="States"
                multiple
              />
            </v-col>
            <v-col cols="3" style="margin-top: 18px">
              <v-autocomplete
                v-model="assignee_id"
                :items="userdata"
                item-text="userName"
                item-value="userID"
                dense
                chips
                small-chips
                label="Assigned User"
              />
            </v-col>
            <v-col cols="6">
              <!-- <v-text-field
                v-on:keyup.enter="searchData"
                sm="1"
                md="1"
                label="OCR"
                v-model="ocr"
              /> -->
              <v-combobox
                class="noicon-combo"
                v-model="ocr"
                sm="1"
                md="1"
                v-on:keyup.enter="searchData"
                :items="ocr_autofills"
                label="OCR"
                ref="ocr"
              />
            </v-col>
            <v-col
              cols="3"
              v-if="
                formType === 'DIRECT_UNPUBLISHED' ||
                formType === 'DIRECT_PUBLISHED' ||
                formType === 'DIRECT_PUBLISHED' ||
                formType === 'PRINTMEDIATMEP' ||
                formType === 'PRINTMEDIAPUBLISHED'
              "
            >
              <!-- <v-text-field
                v-on:keyup.enter="searchData"
                v-model="dmtmsource"
                label="DM/TM Source"
                required
              /> -->
              <v-combobox
                v-on:keyup.enter="searchData"
                v-model="dmtmsource"
                label="DM/TM Source"
                class="noicon-combo"
                :items="dmtmsource_autofills"
                required
                ref="dmtmsource"
              />
            </v-col>

            <v-col cols="3" style="margin-top: 8px">
              <v-radio-group row v-model="country">
                <span style="margin-right: 5px">Country</span>
                <v-radio checked label="ALL" value="all" />
                <v-radio label="CANADA" value="CA" />
                <v-radio label="U.S" value="US" />
              </v-radio-group>
            </v-col>
            <v-col
              cols="1"
              v-if="
                formType !== 'DIRECT_UNPUBLISHED' &&
                formType !== 'DIRECT_PUBLISHED' &&
                formType !== 'PRINTMEDIATMEP' &&
                formType !== 'PRINTMEDIAPUBLISHED' &&
                formType != 'SOCIALMEDIA_UNPUBLISHED' &&
                formType != 'SOCIALMEDIA_PUBLISHED' &&
                formType != 'WEBSITEURL_UNPUBLISHED' &&
                formType != 'WEBSITEURL_PUBLISHED' &&
                formType != 'MOBILEUNPUBLISHED' &&
                formType != 'MOBILEPUBLISHED'
              "
            >
              <!-- <v-text-field
                v-on:keyup.enter="searchData"
                v-model="muid"
                label="Muid"
              /> -->
              <v-combobox
                v-on:keyup.enter="searchData"
                v-model="muid"
                class="noicon-combo"
                :items="muid_autofills"
                label="Muid"
                ref="muid"
              />
            </v-col>
            <v-col
              cols="2"
              v-if="
                formType !== 'DIRECT_UNPUBLISHED' &&
                formType !== 'DIRECT_PUBLISHED' &&
                formType !== 'PRINTMEDIATMEP' &&
                formType !== 'PRINTMEDIAPUBLISHED' &&
                formType != 'SOCIALMEDIA_UNPUBLISHED' &&
                formType != 'SOCIALMEDIA_PUBLISHED' &&
                formType != 'WEBSITEURL_UNPUBLISHED' &&
                formType != 'WEBSITEURL_PUBLISHED' &&
                formType != 'MOBILEUNPUBLISHED' &&
                formType != 'MOBILEPUBLISHED'
              "
            >
              <v-select
                v-model="annotated"
                :items="wrench_items"
                item-value="db_val"
                item-text="val"
                label="Annotation"
                multiple
                hide-details
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <!-- <div id="searchForm__loader"></div> -->
          </v-row>
          <v-row> </v-row>
          <v-row>
            <v-col cols="8" style="padding-top: 22px">
              <v-btn color="primary" small @click="autoFillSearchingData"
                >Search</v-btn
              >
              <v-btn color="default" small @click="reset">Reset</v-btn>
            </v-col>
          </v-row>
        </div>
      </v-expand-transition>
      <v-row no-gutters>
        <span
          @click="expandForm()"
          v-if="expand === false"
          id="searchForm__filters"
        >
          Show Advance Filter
        </span>
        <span
          style="margin-top: 15px"
          @click="expandForm()"
          v-if="expand === true"
          id="searchForm__filters"
        >
          Hide Advance Filter
        </span>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Autocomplete from "./Autocomplete.vue";
export default {
  name: "searchForm",
  data: () => ({
    selectedItem: 1,
    wrench_items: [
      { db_val: 0, val: "Not-Annotated" },
      { db_val: 1, val: "Annotated" },
      { db_val: 2, val: "Reviewed" },
    ],
    expand: false,
    switch1: false,
    companies: [],
    company_working: false,
    companyName: "",
    company: "",
    choosenCompanies: [],
    assigned_user: null,
    message: null,
    typing: null,
    debounce: null,
    dmtmsource_autofills:
      JSON.parse(localStorage.getItem("dmtmsource_autofills")) || [],
    muid_autofills: JSON.parse(localStorage.getItem("muid_autofills")) || [],
    ocr_autofills: JSON.parse(localStorage.getItem("ocr_autofills")) || [],
    productName_autofills:
      JSON.parse(localStorage.getItem("productName_autofills")) || [],
    panelist_autofills:
      JSON.parse(localStorage.getItem("panelist_autofills")) || [],
    product_id_autofills:
      JSON.parse(localStorage.getItem("product_id_autofills")) || [],
    entry_id_autofills:
      JSON.parse(localStorage.getItem("entry_id_autofills")) || [],
  }),
  components: {
    Autocomplete,
  },
  props: ["formType", "stateName"],
  mounted() {
    let view = this;
    if (view.product_id) {
      view.expand = true;
      view.$emit("showButtons");
    }
    if (this.$utils.getCookies("advancefilter") != null) {
      view.expand = view.$utils.getCookies("advancefilter") === true;
      view.switch1 = view.$utils.getCookies("advancefilter") === true;
    }
  },
  computed: {
    ...mapGetters({
      userdata: "Rawdata/getUserData",
      statedata: "Rawdata/getStates",
      assignee_id: "Rawdata/getAssigneeData",
    }),
    product_id: {
      get: function () {
        let count = this.$store.getters[this.stateName + "/getProductId"];
        // Find Exact problem here
        if (count.length > 0) {
          if (this.product_id_autofills.length > 99) {
            this.product_id_autofills.shift();
          }
          this.product_id_autofills.push(
            this.$store.getters[this.stateName + "/getProductId"]
          );
          console.log(
            "this.product_id_autofills",
            typeof this.product_id_autofills
          );
          localStorage.setItem(
            "product_id_autofills",
            JSON.stringify(this.product_id_autofills)
          );
        }

        return this.$store.getters[this.stateName + "/getProductId"];
      },
      set: function (o) {
        this.$store.dispatch(this.stateName + "/setProductId", "");
        if(o !==null || undefined){
          this.$store.dispatch(this.stateName + "/setProductId", o);
        }
      },
    },
    ocr: {
      get: function () {
        let count = this.$store.getters[this.stateName + "/getOcr"];
        if (count.length > 0) {
          if (this.ocr_autofills.length > 99) {
            this.ocr_autofills.shift();
          }
          this.ocr_autofills.push(
            this.$store.getters[this.stateName + "/getOcr"]
          );
          localStorage.setItem(
            "ocr_autofills",
            JSON.stringify(this.ocr_autofills)
          );
        }
        return this.$store.getters[this.stateName + "/getOcr"];
      },
      set: function (o) {
        this.$store.dispatch(this.stateName + "/setOcr", "");
        if(o !== null || undefined ){
          this.$store.dispatch(this.stateName + "/setOcr", o);
        }
        
      },
    },
    productName: {
      get: function () {
        let count = this.$store.getters[this.stateName + "/getProductName"];
        if (count.length > 0) {
          if (this.productName_autofills.length > 99) {
            this.productName_autofills.shift();
          }
          this.productName_autofills.push(
            this.$store.getters[this.stateName + "/getProductName"]
          );
          localStorage.setItem(
            "productName_autofills",
            JSON.stringify(this.productName_autofills)
          );
        }
        return this.$store.getters[this.stateName + "/getProductName"];
      },
      set: function (o) {
        this.$store.dispatch(this.stateName + "/setProductName", "");
        if(o !== null || undefined){
          this.$store.dispatch(this.stateName + "/setProductName", o);
        }
      
      },
    },
    statename: {
      get: function () {
        return this.$store.getters[this.stateName + "/getStates"];
      },
      set: function (o) {
        if(o !== null || undefined){
          this.$store.dispatch(this.stateName + "/setStates", o); 
        }
      },
    },
    username: {
      get: function () {
        return this.$store.getters[this.stateName + "/getUsers"];
      },
      set: function (o) {
        this.$store.dispatch(this.stateName + "/setUsers","");
        if(o !== null || undefined){
          this.$store.dispatch(this.stateName + "/setUsers",o );
        };
      },
    },
    assignee_id: {
      get: function () {
        return this.$store.getters[this.stateName + "/getAssigneeID"];
      },
      set: function (o) {
        this.$store.dispatch(this.stateName + "/setAssigneeID", "");
        if(0!==null || undefined){
          this.$store.dispatch(this.stateName + "/setAssigneeID", o);
        }
      
      },
    },
    muid: {
      get: function () {
        let count = this.$store.getters[this.stateName + "/getMuid"];
        if (count.length > 0) {
          if (this.muid_autofills.length > 99) {
            this.muid_autofills.shift();
          }
          this.muid_autofills.push(
            this.$store.getters[this.stateName + "/getMuid"]
          );
          localStorage.setItem(
            "muid_autofills",
            JSON.stringify(this.muid_autofills)
          );
        }
        return this.$store.getters[this.stateName + "/getMuid"];
      },
      set: function (o) {
        this.$store.dispatch(this.stateName + "/setMuid", "");
        if(o !== null || undefined){
          this.$store.dispatch(this.stateName + "/setMuid", o);
        }
      },
    },
    entry_id: {
      get: function () {
        let count = this.$store.getters[this.stateName + "/getEntryId"];
        if (count.length > 0) {
          if (this.entry_id_autofills.length > 99) {
            this.entry_id_autofills.shift();
          }
          this.entry_id_autofills.push(
            this.$store.getters[this.stateName + "/getEntryId"]
          );
          localStorage.setItem(
            "entry_id_autofills",
            JSON.stringify(this.entry_id_autofills)
          );
        }
        return this.$store.getters[this.stateName + "/getEntryId"];
      },
      set: function (o) {
        this.$store.dispatch(this.stateName + "/setEntryId", "");
        if(o!==null || undefined){
          this.$store.dispatch(this.stateName + "/setEntryId", o);
        }
      },
    },
    panelist: {
      get: function () {
        let count = this.$store.getters[this.stateName + "/getPanelist"];
        if (count.length > 0) {
          if (this.panelist_autofills.length > 99) {
            this.panelist_autofills.shift();
          }
          this.panelist_autofills.push(
            this.$store.getters[this.stateName + "/getPanelist"]
          );
          localStorage.setItem(
            "panelist_autofills",
            JSON.stringify(this.panelist_autofills)
          );
        }
        return this.$store.getters[this.stateName + "/getPanelist"];
      },
      set: function (o) {
        this.$store.dispatch(this.stateName + "/setPanelist", "");   
        if(o!==null || undefined){
          this.$store.dispatch(this.stateName + "/setPanelist", o);      
        }
      },
    },
    country: {
      get: function () {
        return this.$store.getters[this.stateName + "/getCountry"];
      },
      set: function (o) {
        this.updateStates(o);
        this.$store.dispatch(this.stateName + "/setCountry", o);
      },
    },
    dmtmsource: {
      get: function () {
        let count = this.$store.getters[this.stateName + "/getDmTmSource"];
        if (count.length > 0) {
          if (this.dmtmsource_autofills.length > 99) {
            this.dmtmsource_autofills.shift();
          }
          this.dmtmsource_autofills.push(
            this.$store.getters[this.stateName + "/getDmTmSource"]
          );
          localStorage.setItem(
            "dmtmsource_autofills",
            JSON.stringify(this.dmtmsource_autofills)
          );
        }
        return this.$store.getters[this.stateName + "/getDmTmSource"];
      },
      set: function (o) {
        this.$store.dispatch(this.stateName + "/setDmTmSource", "");
        if(o !==null || undefined ){
          this.$store.dispatch(this.stateName + "/setDmTmSource", o);
        }
        
      },
    },
    annotated: {
      get: function () {
        return this.$store.getters[this.stateName + "/getAnnotation"];
      },
      set: function (o) {
        this.$store.dispatch(this.stateName + "/setAnnotation", o);
      },
    },
  },
  methods: {
    expandForm: function () {
      var view = this;
      view.expand = !view.expand;
      view.$emit("showButtons");
    },
    savefilterSetting() {
      var view = this;
      view.$utils.setCookies("advancefilter", view.switch1, view);
    },
    autoFillSearchingData() {
      setTimeout(() => this.searchData(), 100);
    },
    searchData() {
      this.$nextTick(() => {
        this.$refs.product_id.isMenuActive = false;
        this.$refs.entry_id.isMenuActive = false;
        this.$refs.ocr.isMenuActive = false;
        this.$refs.productname.isMenuActive = false;
        this.$refs.panelist.isMenuActive = false;
        if (this.$refs.dmtmsource !== undefined) {
          this.$refs.dmtmsource.isMenuActive = false;
        }
        if (this.$refs.muid !== undefined) {
          this.$refs.muid.isMenuActive = false;
        }
      });
      this.$emit("search");
    },
    reset: function () {
      var view = this;
      view.ocr = "";
      view.productName = "";
      view.statename = "";
      view.username = "";
      view.country = "";
      view.temp_id = "";
      view.muid = "";
      view.entry_id = "";
      view.productName = "";
      view.product_id = "";
      view.panelist = "";
      view.choosenCompanies = [];
      view.dmtmsource = "";
      view.assigned_user = "";
      view.assignee_id = null;
      view.$emit("reset");
    },
    chooseCompany: function (c) {
      var view = this,
        is_company = view.choosenCompanies.filter((o) => c.id === o.id);
      if (Array.isArray(is_company)) {
        if (is_company.length === 0) {
          view.choosenCompanies.push(c);
          view.companies = view.companies.filter((o) => c.id !== o.id);
          view.$emit("addCompany", c.id);
        }
      }
      this.clickedOut();
    },
    clickedOut: function () {
      this.companies = [];
      this.company = "";
    },
    removeCompany: function (c) {
      var view = this;
      view.choosenCompanies = view.choosenCompanies.filter(
        (o) => c.id !== o.id
      );
      if (view.companies.length > 0) {
        view.companies.unshift(c);
      }
      view.$emit("removeCompany", c.id);
    },
    updateStates: function (o) {
      if (typeof o !== "undefined" && o !== "") {
        o = o === "USA" ? "US" : o;
        var view = this,
          url = view.$url("STATE_DATA_URL") + "states/" + o;
        view
          .$fetch({ requiresAuth: true })
          .get(url)
          .then((res) => {
            view.$store.dispatch("Rawdata/setStateData", res.data);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
  },
  watch: {
    company: function (o) {
      var view = this;
      if (o.length > 2) {
        view.companies = [];
        this.message = null;
        this.typing = "You are typing";
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
          this.typing = null;
          this.message = o;
          view
            .$fetch({
              requiresAuth: true,
              operation: "add",
              vueScope: view,
            })
            .post(view.$url("BASE_TEMP_PRODUCT") + "data/company", {
              get_company: o,
            })
            .then(function (res) {
              let data = res.data;
              for (var i = 0; i < data.length; i++) {
                view.companies.push({
                  id: data[i].companyID,
                  name: data[i].companyName,
                });
              }
            })
            .catch(function (e) {
              console.log(e);
            });
        }, 600);
      } else {
        view.companies = [];
      }
    },
  },
};
</script>

<style>
.noicon-combo .v-select__slot .v-input__icon {
  display: none !important;
}
</style>
<style scoped lang="sass">
@import './../../../sass/searchform'
#searchForm__container div.col
  padding-top: 1px
  padding-bottom: 1px
#searchForm__container div.row
  margin-top: 0px
#searchForm__container
  padding-top: 0px
  .v-input--radio-group.v-input--radio-group--row .v-radio
    margin-right: 0px
</style>
