<template>

    <div style="float:right"><v-btn color="primary" small @click.stop = "addnewproduct()" >Add Product</v-btn></div>

</template>

<script>
  export default {
    name: 'Tabs',
    props: ['channel_id'],


    data: () => ({
      
    }),
    methods:{
      addnewproduct(){
       
          var view = this
          let enpointURL =view.$url('ADD_NEW_PRODUCT')+"?channel_id="+this.channel_id
          let popup = window.open(enpointURL,"Add Product","width=900,height=700")
      }

    }
  }
</script>
