<template>
  <v-btn depressed small class="mb-1" color="teal white--text" v-if="filter=='Prefilter'"  @click="copyToClipboard()">
    Copy
  </v-btn>
    <v-btn depressed small class="mb-1" color="teal white--text" v-else  @click="copyToClipboard()">Copy
    </v-btn>
</template>

<script>
export default {
  name: 'searchForm',
  props: {
    muids: {
      type: Array,
      default: () => []
    },
    filter: String
  },
  computed: {
    buttonColor() {
      return this.filter === 'Prefilter' ? 'teal white--text' : '';
    }
  },
  methods: {
      copyToClipboard() {
          console.log("in copy ",this.muids[0]);
      if (this.muids.length === 0) {
        alert('Please select at least one email.');
        return;
      }
     
    const links = this.muids.map(o => o.url);
      console.log("links ",links);
      this.copyToClipboardString(links);
      alert('Copied to clipboard.');
      },
      copyToClipboardString(links) {
      const tempContainer = document.createElement('div');
      links.forEach(url => {
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.innerText = url;
        tempContainer.appendChild(anchor);
        tempContainer.appendChild(document.createElement('br'));
      });

      document.body.appendChild(tempContainer);
      const range = document.createRange();
      range.selectNodeContents(tempContainer);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand('copy');
      document.body.removeChild(tempContainer);
    //   alert(this.successText);
    },

  }
}
</script>
