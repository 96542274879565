<template>
  <v-card
    class="mx-auto"
    max-width="300"
    tile
  >
  <v-row>
      <v-col style="display: inline-flex;">
     <v-text-field
    sm="1"
          md="1"
      v-model="companyName"
      label="Company Name"
      @change="inputchange"
    >

    </v-text-field>
     <v-progress-circular
      indeterminate
      color="primary" v-if="loading"
    ></v-progress-circular>
      </v-col
        >
      </v-row>
    <v-list   style="max-height: 100px"
       class="overflow-y-auto" v-if="showcompanylist == true">
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
      >

        <v-list-item
          v-for="(item, i) in items"
          :key="i"
        >

          <v-list-item-content>
            <v-list-item-title @click="clicklist(item.companyID,item.companyName)" v-text="item.companyName"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

  </v-card>
</template>
<script>
  export default {
    data: () => ({
       selectedItem: 1,
       showcompanylist:false,
       companyid:null,
       companyName:'',
       loading:false,
      items: [],
      

    }),

    computed: {

    },
    methods:{
      inputchange(){
        var  view = this
        view.showcompanylist = true
        let path = this.$url('COMPANY_DATA')
        let data = {}
        this.loading = true
        data['get_company']=this.companyName
       view.$fetch({ requiresAuth: true,operation : 'view', vueScope: view  })
        .post(path,data)
        .then((res) => {
          view.items =   res.data
          setTimeout(()=>{
               view.loading = false
},3000);

          console.log(resdata)
        }) .catch((error) => {
          console.error(error);
        });
        console.log(this.companyName)

      },
      clicklist(companyid,company_name){
        this.showcompanylist=false
        this.companyName =company_name
        this.companyid = companyid

      }
    },

    watch: {
    }
  }
</script>
