<template>
    <div >
        <v-row style="margin-top:-31px">

            <v-col cols="6" sm="10" md="10">
               <v-text-field sm="1" md="1" v-model="company" ref="company" label="Company Name"/>

             </v-col>
             <v-col :cols="1" class="text-left no-left no-bottom no-top pa-0 ma-0">
                <v-progress-circular
                    :width="3"
                    :size="20"
                    color="primary"
                    indeterminate
                    v-if="showSpinner"
                    ></v-progress-circular>
                     </v-col>

        </v-row>

        <v-row style="margin-top:-26px" v-if ="companyList.length > 0">

            <v-col :cols="12">
              <v-icon color="blue" small class="mr-2" @click="close()" style="position: relative;font-size: 23px;left: 83%;top: -46px;">mdi-close-circle-outline</v-icon>
                <div id=companytooltip__tooltip>

                    <ul id="companytooltip__tooltip--list">
                        <li
                        v-for="(a, i) in companyList"
                        v-bind:key="i"
                        v-ripple="{ class: `primary--text` }"
                        @click.prevent="choose(a)"
                        ><span v-if="isSelected(a)"> * </span>{{ a.companyName }}</li>
                    </ul>
                </div>
            </v-col>
        </v-row>

    </div>
</template>

<script>
export default {
    name: 'AddCompanyTooltip',
    data: () => ({
        companyList: [],
        company: '',
        working: false,
        showSpinner:false,
        message: null,
        typing: null,
        debounce: null
    }),
    mounted() {
        this.$refs.company.focus()
    },
    methods: {
        close: function() {
            var view = this
            view.company = ''
            view.companyList=[]
            this.$emit('close', false)
        },
        choose: function(o) {
            var view = this

            if (view.isSelected(o)) {
                alert("You are selecting already selected company.")
            }

            view.$emit('selectCompany', o)
             view.companyList = []
            view.company = ''


        },
        isSelected: function(o) {
            var view  = this

          console.log(o)
        }
    },
    watch: {
        company: function(o) {
            var view = this
            view.companyList = []
              if (o.length > 2)  {
                view.companyList = []
                view.showSpinner = true
                this.message = null
                this.typing = 'You are typing'
                clearTimeout(this.debounce)
                this.debounce = setTimeout(() => {
                    this.typing = null
                    this.message = o
                    view.$fetch({
                    requiresAuth: true,
                    operation : 'add' ,
                    vueScope: view
                }).post(view.$url('BASE_TEMP_PRODUCT') + 'data/company', {get_company: o}).then(function(res) {
                    let r = res.data
                     view.showSpinner = false
                    if (parseInt(r.length) > parseInt(0)) {
                        view.companyList = []
                        r.forEach(function(o) {
                            view.companyList.push(o)
                        })
                    }
                }).catch(e => console.log(e),view.showSpinner = true)
              }, 600)

            }else{
                view.companyList = []
            }

        }
    }
}
</script>
<style lang="sass" scoped>
    @import "../../../sass/addcompanytooltip.sass"
</style>
<style>
.v-progress-circular{
    top:25%

}

</style>
